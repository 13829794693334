@import "slick-carousel/slick/slick.css";
 //@import "slick-carousel/slick/slick-theme.css";
//@import "../node_modules/react-modal-video/scss/modal-video.scss";

$font-family-base: 'Plus Jakarta Sans', 'Be Vietnam', sans-serif;
$body-bg: #F4F7FE;

@import "~bootstrap/scss/bootstrap.scss";
//@import "public/main.scss";
@import "main.scss";
