::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 0;
}
:root {
  --text-color: #48494e;
  --heading: #090f32;
  --prime-one: #ff8c24;
  --prime-two: #ff733b;
  --prime-three: #6a45ff;
  --prime-four: #ffcd45;
  --prime-five: #007bff;
  --prime-six: #ffd338;
  --prime-seven: linear-gradient(-90deg, #ff7df2 0%, #ff275b 100%);
  --prime-eight: #ffed4e;
  --prime-nine: #ff1292;
  --prime-ten: #ff3294;
  --prime-eleven: #ffce4c;
  --prime-twelve: #ffe500;

  --logo-violet: #9a4dff;
  --logo-blue: #228cff;
  --logo-red: #f16264;
  --logo-yellow: #ffd731;
  --logo-green: #05c1a1;

  --logo-violet-extra-light: rgba(154, 77, 255, 0.17);
  --logo-blue-darken: #2337a5;
}
::selection {
  background-color: #2a2a2a;
  color: #fff;
}

/***
====================================================================
  Loading Transition
====================================================================

 ***/
.ctn-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  background: #fff;
}
.ctn-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
  text-align: center;
}
.ctn-preloader .animation-preloader .icon {
  display: inline-block;
  position: relative;
}
.ctn-preloader .animation-preloader .icon img {
  animation: rotated 12s infinite linear;
}
.ctn-preloader .animation-preloader .txt-loading {
  user-select: none;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  color: var(--prime-ten);
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 30px;
  position: absolute;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: "Recoleta";
  font-weight: 700;
  letter-spacing: 12px;
  display: inline-block;
  color: rgb(255 50 148 / 20%);
  position: relative;
  font-size: 35px;
  line-height: 30px;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}
.ctn-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}
.loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}
@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}
@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 40px;
    letter-spacing: 10px;
  }
}
/*^^^^^^^^^^^^^^^^^^^^ Global Prefix ^^^^^^^^^^^^^^^^^^^^^*/
.main-page-wrapper {
  overflow-x: hidden;
  min-width: 380px;
}
//body {
//  font-family: "gordita";
//  font-weight: normal;
//  font-size: 16px;
//  position: relative;
//  color: var(--text-color);
//}
.h1,
h1,
.h2,
h2,
.h3,
h3,
.h4,
h4,
.h5,
h5,
.h6,
h6 {
  color: var(--heading);
}
.h1,
h1 {
  font-size: 80px;
  line-height: 1.18em; /*done*/
}
.h2,
h2 {
  font-size: 58px;
  line-height: 1.05em;
  letter-spacing: -1px; /*done*/
}
.h3,
h3 {
  font-size: 32px; /*done*/
}
.h4,
h4 {
  font-size: 24px;
  line-height: 1.54em; /*done*/
}
.h5,
h5 {
  font-size: 22px;
  line-height: 1.45em; /*done*/
}
.h6,
h6 {
  font-size: 18px; /*done*/
}
p {
  line-height: 1.87em;
}
::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}
::placeholder {
  color: rgba(0, 0, 0, 0.6);
}
/*^^^^^^^^^^^^^^^^^^^^ Prefix Classes ^^^^^^^^^^^^^^^^^^^^^*/
.tran3s,
.svg path {
  transition: all 0.3s ease-in-out;
}
.tran4s {
  transition: all 0.4s ease-in-out;
}
.tran5s {
  transition: all 0.5s ease-in-out;
}
.tran6s {
  transition: all 0.6s ease-in-out;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
.fs-24 {
  font-size: 24px;
}
.fw-500 {
  font-weight: 500;
}
.tx-dark {
  color: #000;
}
.p-30 {
  padding: 30px;
}
.font-recoleta {
  font-family: "Recoleta";
}
.text-lg {
  font-size: 24px;
  line-height: 1.67em;
}
.text-xlg {
  font-size: 28px;
  line-height: 1.67em;
}
.style-none {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.shapes {
  position: absolute;
  z-index: -1;
}
.border-top {
  border-top: 1px solid #f1f1f1 !important;
}
.border-bottom {
  border-bottom: 1px solid #f1f1f1 !important;
}
.zn2 {
  z-index: 2;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-three:before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  background: linear-gradient(0.25turn, #0149ae, #cb6de5);
  background-size: cover;
  z-index: -1;
}

.hero-banner-three .skip-bottom {
  background-color: #F4F7FE;
  border-top-right-radius: 300px;
  border-top-left-radius: 300px;
  //height: 500px;
  height: auto;
  margin-top: 200px;
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: 0;
}


.hero-banner-three .slogan {
  line-height: initial;
  border-radius: 20px;
  padding: 5px 10px 5px 15px;
  background: rgba(255, 255, 255, 0.22);
  color: #000;
  font-size: 16px;
}

.hero-banner-three .slogan i {
  font-size: 12px;
  width: 30px;
  line-height: 22px;
  background: rgba(255, 255, 255, 0.4);
  text-align: center;
  border-radius: 20px;
  display: inline-block;
  vertical-align: 2px;
  margin-left: 22px;
  transition: all 0.3s ease-in-out;
}

.hero-banner-three .slogan:hover i {
  background: var(--prime-three);
  color: #fff;
}

.hero-banner-three .hero-heading {
  font-size: 80px;
  line-height: 1.22em;
  letter-spacing: -2px;
}

.h-hero-heading {
  font-size: 50px;
  line-height: 1.22em;
  letter-spacing: -2px;
}

.hero-banner-three .download-btn button,
.hero-banner-three .download-btn a {
  line-height: 60px;
  padding: 0 40px;
  color: #fff;
  background: var(--logo-blue);
  border-radius: 50px;
  font-size: 30px;
}

.hero-banner-three .download-btn button.show:after {
  transform: rotate(180deg);
}

.hero-banner-three .download-btn button.show,
.hero-banner-three .download-btn a.show,
.hero-banner-three .download-btn button:hover,
.hero-banner-three .download-btn a:hover {
  background: var(--logo-blue-darken);
}

.hero-banner-three .download-btn .dropdown-menu {
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  margin: -2px 0 0 0 !important;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.04);
}

.hero-banner-three .download-btn .dropdown-menu a {
  font-size: 15px;
  color: #000;
  padding: 12px 15px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.hero-banner-three .download-btn .dropdown-menu a:hover,
.hero-banner-three .download-btn .dropdown-menu a:focus {
  background: rgba(0, 0, 0, 0.02);
}

.hero-banner-three .video-icon i {
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  line-height: 58px;
  border-radius: 50%;
  text-align: center;
  font-size: 36px;
  padding-left: 5px;
  transition: all 0.3s ease-in-out;
  color: rgba(0, 0, 0, 0.8);
}

.hero-banner-three .video-icon span {
  color: rgba(0, 0, 0, 0.3);
  letter-spacing: 2.5px;
  margin-bottom: 4px;
}

.hero-banner-three .video-icon:hover i {
  background: var(--prime-three);
  border-color: var(--prime-three);
  color: #fff;
}

.hero-banner-three h2 {
  font-size: 38px;
  letter-spacing: 0px;
}

.hero-banner-three .mobile-screen {
  position: absolute;
  top: 18%;
  right: 3%;
  width: 37.15%;
}

.hero-banner-three .mobile-screen .screen-one {
  box-shadow: -40px 40px 80px rgba(0, 60, 130, 0.03);
  border-radius: 32px;
}

.hero-banner-three .mobile-screen .screen-two {
  box-shadow: -20px 40px 80px rgba(0, 60, 130, 0.03);
  border-radius: 32px;
}

.hero-banner-three .mobile-screen .card-one {
  background: #fff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 28px 15px;
}

.hero-banner-three .mobile-screen .card-one .icon {
  width: 45px;
  height: 45px;
  color: #fff;
  font-size: 17px;
  background: #ff54b0;
}

.hero-banner-three .mobile-screen .card-one h6 {
  width: calc(100% - 45px);
  line-height: 27px;
  letter-spacing: -0.5px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Nine ^^^^^^^^^^^^^^^^^^^^^^^^*/
.fancy-feature-nine .screen-container {
  max-width: 630px;
}

.fancy-feature-nine .screen-container .bg-round-shape {
  right: 13%;
  top: 50%;
  transform: translateY(-50%);
}

.card-style-five {
  padding: 65px 45px 65px;
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(6, 24, 67, 0.05);
  border-radius: 10px;
  text-align: left;
  margin: 40px 0;
}

.card-style-five .icon {
  height: 45px;
}

.card-style-five h4 {
  font-size: 24px;
  line-height: 1.5em;
  letter-spacing: -0.5px;
  padding-top: 45px;
}

/*^^^^^^^^^^^^^^^^^^^^^ Theme Menu ^^^^^^^^^^^^^^^^^^^^^^^^*/
.theme-menu-one.theme-main-menu {
  padding-top: 50px;
}

.theme-main-menu {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 60px;
  transition: all 0.4s ease-out;
}

.theme-main-menu.sticky-menu.fixed {
  position: fixed;
  z-index: 990;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #fff;
  box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.1);
}

.theme-main-menu .logo {
  min-height: 50px;
  display: flex;
  align-items: center;
}

.theme-main-menu .contact-btn-one {
  line-height: 52px;
  border-radius: 6px;
  background: #000;
  padding: 0 35px;
}

.theme-main-menu .contact-btn-one:hover {
  background: var(--prime-one);
}

.theme-main-menu .contact-btn-two {
  line-height: 48px;
  border: 1px solid var(--prime-two);
  color: var(--prime-two);
  border-radius: 30px;
  padding: 0 35px;
}

.theme-main-menu .contact-btn-two:hover {
  background: var(--prime-two);
  color: #fff;
}

.theme-main-menu .contact-btn-three {
  line-height: 50px;
  color: #fff;
  border-radius: 30px;
  padding: 0 35px;
  background: var(--prime-five);
}

.theme-main-menu .contact-btn-three:hover {
  background: var(--prime-two);
  color: #fff;
}

.theme-main-menu .right-widget .contact-btn-four {
  color: #000;
  position: relative;
  z-index: 1;
  height: 70px;
}

.theme-main-menu .right-widget .contact-btn-four em {
  position: relative;
  display: inline-block;
  font-style: normal;
}

.theme-main-menu .right-widget .contact-btn-four em:after,
.theme-main-menu .right-widget .contact-btn-four span:after {
  content: "";
  position: absolute;
  width: 107%;
  height: 2px;
  left: 0;
  bottom: 0;
  background: #000;
}

.theme-main-menu .right-widget .contact-btn-four span {
  display: inline-block;
  color: #fff;
  position: relative;
  padding-left: 5px;
}

.theme-main-menu .right-widget .contact-btn-four span:before {
  content: "";
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: #000;
  z-index: -1;
  top: 50%;
  transform: translateY(-50%);
  right: -36px;
  transition: all 0.3s ease-in-out;
}

.theme-main-menu .right-widget .contact-btn-four:hover span:before {
  background: var(--prime-ten);
}

.theme-main-menu .right-widget .contact-btn-four span:after {
  background: #fff;
}

.theme-main-menu .right-widget .contact-btn-four i {
  color: #fff;
  margin-left: 3px;
}

.theme-main-menu .login-btn-one {
  line-height: 48px;
  border: 1px solid #e4e4e4;
  color: #000;
  border-radius: 30px;
  padding: 0 35px;
}

.theme-main-menu .login-btn-one:hover {
  background: var(--prime-two);
  color: #fff;
}

.theme-main-menu .login-btn-two {
  font-size: 18px;
  color: #000;
}

.theme-main-menu .login-btn-three {
  width: 55px;
  height: 55px;
  border: 1px solid #e9e9e9;
  text-align: center;
  line-height: 53px;
  color: var(--prime-ten);
  font-size: 25px;
}

.theme-main-menu.white-vr .login-btn-three {
  background: #fff;
}

.theme-main-menu .login-btn-three:hover {
  background: var(--prime-ten);
  border-color: var(--prime-ten);
  color: #fff;
}

.theme-main-menu .login-btn-four {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  background: #000;
  font-size: 25px;
}

.theme-main-menu .login-btn-four:hover {
  background: var(--prime-twelve);
  color: #000;
}

.theme-main-menu .signup-btn-one {
  font-size: 18px;
  color: #000;
}

.theme-main-menu .login-btn-two:hover,
.theme-main-menu .signup-btn-one:hover span {
  text-decoration: underline;
}

.theme-main-menu .signup-btn-one:before {
  content: "";
  position: absolute;
  height: 13px;
  width: 2px;
  background: #000;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.theme-main-menu .signup-btn-two {
  line-height: 50px;
  border-radius: 28px;
  letter-spacing: -0.36px;
  color: var(--prime-eight);
  border: 2px solid var(--prime-eight);
  padding: 0 26px;
}

.theme-main-menu .signup-btn-two:hover {
  background: var(--prime-eight);
  color: #000;
}

.theme-main-menu .signup-btn-three {
  line-height: 45px;
  border-radius: 28px;
  letter-spacing: -0.36px;
  color: #fff;
  background: #000;
  padding: 0 28px;
  @media (min-width: 1400px) and (max-width: 1700px) {
    padding: 0px 20px;
    font-size: 14px;
  }
}

.theme-main-menu .signup-btn-three:hover {
  background: var(--prime-eleven);
  color: #000;
}

.theme-main-menu .signup-btn-four {
  line-height: 50px;
  border-radius: 28px;
  letter-spacing: -0.36px;
  color: #fff;
  background: #000;
  padding: 0 28px;
}

@media (max-width: 1800px) {
  .theme-main-menu .signup-btn-four {
    padding: 0 21px;
  }
}

.theme-main-menu .signup-btn-four:hover {
  background: var(--prime-twelve);
  color: #000;
}

.theme-main-menu .call-btn-one {
  color: #fff;
}

.theme-main-menu .call-btn-one:hover {
  text-decoration: underline;
}

.theme-main-menu .lang-dropdown:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 45px;
  background: rgba(216, 216, 216, 0.2);
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
}

.theme-main-menu .lang-dropdown button {
  font-weight: 500;
  color: #fff;
  font-size: 17px;
  border: none;
  padding: 0 15px 0 0;
  line-height: initial;
  position: relative;
}

.theme-main-menu .lang-dropdown button:after {
  display: none;
}

.theme-main-menu .lang-dropdown button:before {
  content: "\f282";
  font-family: bootstrap-icons !important;
  position: absolute;
  right: 0;
  font-size: 13px;
  line-height: 23px;
}

.theme-main-menu .lang-dropdown .dropdown-menu {
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0;
  min-width: 130px;
}

.theme-main-menu .lang-dropdown .dropdown-item {
  line-height: 35px;
  padding: 0 15px;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
}

.theme-main-menu .lang-dropdown .dropdown-item:hover {
  background: var(--prime-four);
  color: #fff;
}

.theme-main-menu .donate-btn {
  font-size: 17px;
  line-height: 55px;
  border-radius: 5px;
  color: #000;
  padding: 0 40px;
  background: var(--prime-four);
}

.theme-main-menu .donate-btn:hover {
  background: #fff;
}

.theme-main-menu .start-btn-one {
  line-height: 52px;
  border: 2px solid #363636;
  padding: 0 36px;
  color: #000;
}

.theme-main-menu .start-btn-one:before {
  content: "";
  position: absolute;
  width: calc(100% + 3px);
  height: calc(100% + 3px);
  top: 3px;
  left: 3px;
  z-index: -1;
  background: var(--prime-six);
  transition: all 0.3s ease-in-out;
}

.theme-main-menu .start-btn-one:hover {
  border-color: var(--prime-six);
}

.theme-main-menu .start-btn-one:hover:before {
  top: 0;
  left: 0;
}

.theme-main-menu .start-btn-two {
  font-size: 18px;
  line-height: 55px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.17);
  padding: 0 36px;
  color: #fff;
}

.theme-main-menu .start-btn-two:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 15px;
  background: var(--prime-seven);
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.theme-main-menu .start-btn-two:hover:before {
  opacity: 1;
}

.theme-main-menu .header-search-one {
  width: 420px;
  height: 45px;
  margin-left: 7.5vw;
}

.theme-main-menu .header-search-one input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px solid #000;
  border-radius: 30px;
  font-size: 15px;
  color: #000;
  padding: 0 15px 0 50px;
}

.theme-main-menu .header-search-one .icon {
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
}

.theme-main-menu .header-search-two {
  width: 420px;
  height: 45px;
  margin-left: 7.5vw;
}

.theme-main-menu .header-search-two input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  border-bottom: 2px solid #000;
  font-size: 15px;
  color: #000;
  font-style: italic;
  padding: 0 15px 0 35px;
}

.theme-main-menu .header-search-two .icon {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.theme-main-menu .go-back-btn {
  color: #000;
}

.theme-main-menu .go-back-btn:hover {
  color: var(--prime-ten);
}

/***************** White Version ********************/
.white-vr .navbar .navbar-nav .nav-link,
.white-vr.theme-main-menu .signup-btn-one,
.white-vr.theme-main-menu .login-btn-two {
  color: #fff;
}

.white-vr .navbar .navbar-nav .nav-item.active .nav-link,
.white-vr .navbar .navbar-nav .nav-item:hover .nav-link {
  color: #fff;
  text-decoration: underline;
}

.white-vr.theme-main-menu .signup-btn-one:before {
  background: #fff;
}

.white-vr.theme-main-menu.sticky-menu.fixed {
  background: #090f32;
}


/*^^^^^^^^^^^^^^^^^^^^^ Feature Section Eight ^^^^^^^^^^^^^^^^^^^^^^^^*/
.block-style-three .list-item li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
}

.block-style-three .list-item li:before {
  content: " ";
  background: url("/images/check-mark.svg") no-repeat;
  top: 5px;
  width: 25px;
  height: 25px;
  font-size: 13px;
  position: absolute;
  left: 0;
  color: var(--prime-three);
}

.block-style-three .list-item.text-white li:before {
  color: rgba(255, 255, 255, 0.75);
}

.platform-button-group a {
  width: 190px;
  height: 58px;
  padding: 0 5px 0 25px;
  margin: 10px 25px 0 0;
  background: #1b1b1b;
  border: 1px solid transparent;
  border-radius: 6px;
  color: #fff;
  text-align: left;
  transition: all 0.3s ease-in-out;
}

.platform-button-group a:hover {
  transform: translateY(-5px);
  box-shadow: -5px 10px 30px rgba(0, 0, 0, 0.05);
}

.platform-button-group a .icon {
  margin-right: 14px;
}

.platform-button-group a span {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  line-height: initial;
}

.platform-button-group a strong {
  font-weight: 500;
  font-size: 18px;
  display: block;
}

.platform-button-group a.windows-button {
  background: #fff;
  border-color: #dadada;
}

.platform-button-group a.windows-button span {
  color: #999999;
}

.platform-button-group a.windows-button strong {
  color: #000;
}

.fancy-feature-eight .illustration-holder {
  position: absolute;
  width: 41%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.fancy-feature-eight .illustration-holder .main-img {
  border-radius: 0 25px 25px 0;
  box-shadow: 0 18px 27px rgba(0, 0, 0, 0.03);
}

.fancy-feature-eight .illustration-holder .screen-one {
  z-index: 1;
  right: -8%;
  bottom: -4%;
  border-radius: 15px;
  width: 41.4%;
  box-shadow: -30px 50px 100px rgba(0, 20, 63, 0.1);
}

.fancy-feature-eight .illustration-holder .shape-one {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

/*^^^^^^^^^^^^^^^^^^^^ Section Title ^^^^^^^^^^^^^^^^^^^^^^*/
.title-style-one .sc-title {
  font-size: 16px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.3);
  margin-bottom: 8px;
}

.title-style-one .main-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.title-style-one .main-title span:before {
  content: "";
  position: absolute;
  left: 4px;
  bottom: 10px;
  width: 98%;
  height: 12px;
  background: rgba(255, 139, 37, 0.4);
  z-index: -1;
}

.title-style-two .sc-title {
  font-size: 20px;
  padding-bottom: 5px;
  color: #f96f60;
}

.title-style-two .main-title {
  font-size: 48px;
  line-height: 1.2em;
}

.title-style-three .sc-title {
  font-size: 14px;
  letter-spacing: 1px;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.3);
}

.title-style-three .main-title {
  font-family: "Recoleta";
  font-size: 64px;
  line-height: 1.15em;
  margin: 0;
}

.title-style-three .main-title > span {
  z-index: 1;
}

.title-style-three .main-title .mark-bg {
  position: absolute;
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  width: 98%;
  height: 50px;
  z-index: -1;
}

.title-style-three .icon {
  width: 90px;
  height: 90px;
  margin: 0 auto 45px;
}

.title-style-four .main-title {
  letter-spacing: -1.5px;
}

.title-style-four .main-title span {
  display: inline-block;
  position: relative;
  z-index: 1;
}

.title-style-four .main-title span:before {
  content: "";
  position: absolute;
  left: 3px;
  bottom: 13px;
  width: 97%;
  height: 12px;
  background: #ff5bd7;
  opacity: 0.5;
  z-index: -1;
}

.title-style-four .main-title span.violet:before {
  background: #9a4dff;
}

.title-style-four .main-title span.blue:before {
  background: #228cff;
}

.title-style-four .main-title span.red:before {
  background: #f16264;
}

.title-style-four .main-title span.yellow:before {
  background: #FFD731;
}

.title-style-four .main-title span.green:before {
  background: #05c1a1;
}

.title-style-four h3.main-title  span:before {
  bottom: 2px;
  height: 8px;
}

.title-style-four h2.main-title  span:before {
  bottom: 8px;
}

.title-style-four .sc-title {
  font-size: 24px;
  padding-bottom: 10px;
  color: rgba(0, 0, 0, 0.3);
}

.title-style-four .sc-title-two {
  font-size: 15px;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding-bottom: 22px;
}

.title-style-five .sc-title {
  font-size: 16px;
  color: #bebebe;
  letter-spacing: 4px;
  padding-bottom: 20px;
}

.title-style-five .sc-title-two {
  font-size: 17px;
  color: var(--prime-ten);
  padding-left: 40px;
  margin-bottom: 20px;
}

.title-style-five .sc-title-two:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 1px;
  background: var(--prime-ten);
  left: 0;
  top: 14px;
}

.title-style-five .main-title {
  font-size: 72px;
  line-height: 1.25em;
}

.title-style-five .main-title > span {
  z-index: 1;
}

.title-style-five .main-title .mark-bg {
  position: absolute;
  left: 0;
  bottom: 17px;
  width: 100%;
  height: 13px;
  z-index: -1;
}

.title-style-six .sc-title {
  font-family: "Noteworthy Light";
  font-size: 20px;
  letter-spacing: 3px;
  padding-bottom: 6px;
  text-transform: uppercase;
}

.title-style-six .sc-title-two {
  font-size: 15px;
  letter-spacing: 2px;
  padding-bottom: 8px;
  color: var(--prime-ten);
}

.title-style-six .main-title {
  font-size: 62px;
  line-height: 1.32em;
}

.title-style-seven .main-title {
  font-size: 68px;
  line-height: 1.35em;
  letter-spacing: -2px;
}

.title-style-seven .main-title span img {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  max-width: 113%;
  transform: translate(-50%, -50%);
}

.title-style-eight .sc-title {
  font-size: 13px;
  letter-spacing: 3px;
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 15px;
}

.title-style-eight .main-title {
  font-size: 68px;
  line-height: 1.25em;
  letter-spacing: -1px;
}

.title-style-eight .main-title span {
  line-height: 64px;
}

.title-style-eight .main-title span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: var(--prime-six);
}

.title-style-nine .sc-title {
  font-size: 18px;
  padding-bottom: 16px;
  color: rgba(255, 255, 255, 0.3);
}

.title-style-nine .main-title span {
  background: linear-gradient(270.78deg, #ffa7a9 4.19%, #ff5ea3 96.11%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-style-ten .sc-title {
  font-size: 15px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(--prime-nine);
  padding-bottom: 16px;
}

.title-style-ten .main-title {
  letter-spacing: 0;
  line-height: 1.17em;
}

.title-style-ten .main-title img {
  position: absolute;
  bottom: 9px;
  left: 0;
  z-index: -1;
}

.title-style-eleven .main-title {
  font-size: 64px;
  line-height: 1.25em;
  letter-spacing: -2px;
}

.title-style-eleven .sc-title {
  font-size: 15px;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
}

.title-style-twelve .sc-title {
  font-size: 17px;
  color: var(--prime-ten);
  padding-left: 40px;
  margin-bottom: 12px;
}

.title-style-twelve .sc-title:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 1px;
  background: var(--prime-ten);
  left: 0;
  top: 14px;
}

.title-style-twelve .main-title {
  font-size: 62px;
  line-height: 1.15em;
}

.title-style-thirteen .main-title {
  font-size: 55px;
  letter-spacing: 0px;
  line-height: 1.17em;
}

.title-style-fourteen .sc-title {
  font-size: 15px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: var(--prime-nine);
  padding-bottom: 16px;
}

.title-style-fourteen .main-title {
  font-size: 86px;
  letter-spacing: 0;
  line-height: 1.17em;
}

.title-style-fourteen .main-title img {
  position: absolute;
  bottom: 9px;
  left: 0;
  z-index: -1;
}

.title-style-fourteen .sc-title {
  font-size: 15px;
  letter-spacing: 4px;
  text-transform: uppercase;
  color: var(--prime-nine);
  padding-bottom: 16px;
}

.title-style-fourteen .main-title {
  font-size: 86px;
  letter-spacing: 0;
  line-height: 1.17em;
}

.title-style-fourteen .main-title img {
  position: absolute;
  bottom: 9px;
  left: 0;
  z-index: -1;
}

/*^^^^^^^^^^^^^^^^^^^^^ Feedback Section Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.feedback-section-three .slider-wrapper {
  width: calc(1320px + 30vw);
  margin: 0 -20px;
}

.feedback_slider_three .item {
  padding: 0 20px;
}

.feedback-block-three {
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(19, 45, 73, 0.04);
  border-radius: 10px;
  margin: 40px 0 135px;
  padding: 55px 40px;
}

.feedback-block-three .rating li {
  font-size: 16px;
  color: #ffc12b;
  margin-right: 8px;
}

.feedback-block-three p {
  font-size: 20px;
  line-height: 35px;
  margin: 22px 0 40px;
}

.feedback-block-three .name span {
  font-size: 16px;
  color: #c1c1c1;
  margin-top: 3px;
}

.feedback-block-three .avatar {
  width: 45px;
  height: 45px;
}

.feedback_slider_three .slick-dots {
  text-align: center;
}

.slick-dots li {
  display: inline-block;
}

.slick-dots li button {
  text-indent: -50000px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  margin: 0 5px;
  transition: all 0.3s ease-in-out;
}

.slick-dots li.slick-active button {
  background: var(--prime-three);
  transform: scale(1.7);
}

.feedback-section-three .shape-holder {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 41%;
}

.feedback-section-three .shape-holder [class*="avatar-"] {
  box-shadow: 0 20px 40px rgba(36, 11, 74, 0.1);
  z-index: 1;
}

.feedback-section-three .shape-holder .avatar-one {
  bottom: 17%;
  left: 14%;
  animation: jumpFour 3.5s infinite linear;
}

.feedback-section-three .shape-holder .avatar-two {
  top: -1%;
  right: 20%;
  animation: jumpTwo 3.5s infinite linear;
}

.feedback-section-three .shape-holder .avatar-three {
  top: 21%;
  left: 14%;
  animation: jumpFour 3.5s infinite linear;
}

.feedback-section-three .shape-holder .avatar-four {
  bottom: 3%;
  right: 26%;
  animation: jumpTwo 3.5s infinite linear;
}


/*^^^^^^^^^^^^^^^^^^^^^ Theme Hero Banner Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.hero-banner-bottom {
  //top: -200px
}

.hero-banner-bottom:before {
  content: "";
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  background: linear-gradient(0.25turn, #0149ae, #cb6de5);

  background-size: cover;
  z-index: -1;
}

.hero-banner-bottom .skip-bottom {
  background-color: #F4F7FE;
  border-bottom-right-radius: 300px;
  border-bottom-left-radius: 300px;
  height: 500px;
  margin-top: 200px;
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: 100px;
}


.hero-banner-bottom .slogan {
  line-height: initial;
  border-radius: 20px;
  padding: 5px 10px 5px 15px;
  background: rgba(255, 255, 255, 0.22);
  color: #000;
  font-size: 16px;
}

.hero-banner-bottom .slogan i {
  font-size: 12px;
  width: 30px;
  line-height: 22px;
  background: rgba(255, 255, 255, 0.4);
  text-align: center;
  border-radius: 20px;
  display: inline-block;
  vertical-align: 2px;
  margin-left: 22px;
  transition: all 0.3s ease-in-out;
}

.hero-banner-bottom .slogan:hover i {
  background: var(--prime-three);
  color: #fff;
}

.hero-banner-bottom .hero-heading {
  font-size: 90px;
  line-height: 1.22em;
  letter-spacing: -2px;
}

.hero-banner-bottom .download-btn button {
  line-height: 60px;
  padding: 0 40px;
  color: #fff;
  background: #262626;
  border-radius: 50px;
}

.hero-banner-bottom .download-btn button.show:after {
  transform: rotate(180deg);
}

.hero-banner-bottom .download-btn button.show,
.hero-banner-bottom .download-btn button:hover {
  background: var(--prime-three);
}

.hero-banner-bottom .download-btn .dropdown-menu {
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  border: none;
  margin: -2px 0 0 0 !important;
  box-shadow: 0 20px 35px rgba(0, 0, 0, 0.04);
}

.hero-banner-bottom .download-btn .dropdown-menu a {
  font-size: 15px;
  color: #000;
  padding: 12px 15px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.hero-banner-bottom .download-btn .dropdown-menu a:hover,
.hero-banner-bottom .download-btn .dropdown-menu a:focus {
  background: rgba(0, 0, 0, 0.02);
}

.hero-banner-bottom .video-icon i {
  width: 60px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  line-height: 58px;
  border-radius: 50%;
  text-align: center;
  font-size: 36px;
  padding-left: 5px;
  transition: all 0.3s ease-in-out;
  color: rgba(0, 0, 0, 0.8);
}

.hero-banner-bottom .video-icon span {
  color: rgba(0, 0, 0, 0.3);
  letter-spacing: 2.5px;
  margin-bottom: 4px;
}

.hero-banner-bottom .video-icon:hover i {
  background: var(--prime-three);
  border-color: var(--prime-three);
  color: #fff;
}

//.hero-banner-bottom h2 {
//  font-size: 38px;
//  letter-spacing: 0px;
//}

.hero-banner-bottom .mobile-screen {
  position: absolute;
  top: 18%;
  right: 3%;
  width: 37.15%;
}

.hero-banner-bottom .mobile-screen .screen-one {
  box-shadow: -40px 40px 80px rgba(0, 60, 130, 0.03);
  border-radius: 32px;
}

.hero-banner-bottom .mobile-screen .screen-two {
  box-shadow: -20px 40px 80px rgba(0, 60, 130, 0.03);
  border-radius: 32px;
}

.hero-banner-bottom .mobile-screen .card-one {
  background: #fff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 28px 15px;
}

.hero-banner-bottom .mobile-screen .card-one .icon {
  width: 45px;
  height: 45px;
  color: #fff;
  font-size: 17px;
  background: #ff54b0;
}

.hero-banner-bottom .mobile-screen .card-one h6 {
  width: calc(100% - 45px);
  line-height: 27px;
  letter-spacing: -0.5px;
}


.bg-round-bubble {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--logo-violet-extra-light);
    border-radius: 50%;
    z-index: -1;
    top: 0;
    left: 0;

    //&.about {
    //  background: #f7fbfe;;
    //}
  }

  &.about:before {
    background: #f7fbfe;;
  }
}



.footer-top {
  background-color: #F4F7FE;
  border-bottom-right-radius: 300px;
  border-bottom-left-radius: 300px;
  height: 340px;
  margin-left: -50px;
  margin-right: -50px;
  margin-bottom: 50px;
  padding-top: 100px;
  padding-left: 50px;
  padding-right: 50px;

  @media (min-width: 0) and (max-width: 600px) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    height: 250px;
  }

  @media (min-width: 600px) and (max-width: 1200px) {
    height: 280px;
  }
}


/*^^^^^^^^^^^^^^^^^^^^^ Footer Three ^^^^^^^^^^^^^^^^^^^^^^^^*/
.footer-style-three {
  z-index: 1;
}

.footer-style-three:before {

}

.footer-style-three .subscribe-form {
  max-width: 620px;
}

.footer-style-three .subscribe-form form {
  height: 70px;
}

.footer-style-three .subscribe-form input {
  width: 100%;
  height: 100%;
  border: none;
  background: #fff;
  border-radius: 35px;
  padding: 0 180px 0 40px;
}

.footer-style-three .subscribe-form button {
  width: 180px;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0 35px 35px 0;
  background: #ff43a1;
  color: #fff;
}

.footer-style-three .subscribe-form button:hover {
  background: var(--prime-two);
}

.footer-style-three .bottom-footer h4 {
  font-size: 22px;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.footer-style-three .bottom-footer .social-icon a {
  width: 37px;
  height: 37px;
  border: 1px solid #fff;
  border-radius: 50%;
  line-height: 35px;
  color: #fff;
  margin: 0 6px;
  transition: all 0.3s ease-in-out;
}

.footer-style-three .bottom-footer .social-icon a:hover {
  background: var(--prime-two);
  border-color: var(--prime-two);
}

.footer-style-three .bottom-footer .mail {
  color: rgba(255, 255, 255, 0.95);
}

.footer-style-three .bottom-footer .mail:hover {
  text-decoration: underline;
  color: #fff;
}

.footer-style-three .bg-illustration {
  bottom: 0;
  left: 0;
  animation: fade-in 2.5s infinite linear;
}


// pricing

/*^^^^^^^^^^^^^^^^^^^^^ Pricing Section Four ^^^^^^^^^^^^^^^^^^^^^^^^*/
.pricing-section-four .wrapper {
  max-width: 1744px;
  padding-left: 12px;
  padding-right: 12px;
}

.pricing-table-area-four .pr-table-wrapper {
  border: 1px solid #9a4dff;
  background-color: #fff;
  padding: 30px 35px 40px;
  //margin-top: 40px;
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.pricing-table-area-four .pr-table-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 2px solid #000;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.pricing-table-area-four .pr-table-wrapper .pack-name {
  font-size: 28px;
}

.pricing-table-area-four .pr-table-wrapper .pack-details {
  color: #2a2a2a;
  letter-spacing: 2px;
  padding: 5px 0 35px;
}

.pricing-table-area-four .pr-table-wrapper .top-banner {
  padding: 9px 5px 10px 25px;
  color: #000;
  text-align: left;
}

.pricing-table-area-four .pr-table-wrapper .price {
  font-size: 40px;
  padding: 0 22px 0 0;
}

.pricing-table-area-four .pr-table-wrapper .price sup {
  font-size: 20px;
  top: -13px;
  left: -4px;
}

.pricing-table-area-four .pr-table-wrapper .top-banner span {
  font-size: 22px;
}

.pricing-table-area-four .pr-table-wrapper .top-banner em {
  font-size: 14px;
  font-style: normal;
  margin-top: -2px;
}

.pricing-table-area-four .pr-table-wrapper .pr-feature {
  text-align: left;
  padding: 50px 0 57px 23px;
}

.pricing-table-area-four .pr-table-wrapper .pr-feature li {
  font-size: 18px;
  position: relative;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.7);
  padding-left: 30px;
}

.pricing-table-area-four .pr-table-wrapper .pr-feature li:before {
  content: " ";
  //background: url("/images/check-mark.svg") no-repeat;
  top: 15px;
  width: 25px;
  height: 25px;
  font-size: 13px;
  position: absolute;
  left: 0;
  color: #000;
}

.pricing-table-area-four .pr-table-wrapper .trial-button {
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  line-height: 52px;
  border: 2px solid #000;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.pricing-table-area-four .pr-table-wrapper .btn-fourteen {
  line-height: 56px;
  font-size: 16px;
}

.pricing-table-area-four .pr-table-wrapper:hover:before,
.pricing-table-area-four .pr-table-wrapper.active:before {
  opacity: 1;
}

.pricing-table-area-four .pr-table-wrapper .trial-button:hover,
.pricing-table-area-four .pr-table-wrapper.active .trial-button {
  background: #000;
  color: #fff;
}

.pricing-section-four .consult-text {
  background: #f2f2f2;
  border-radius: 30px;
  padding: 5px 25px;
}

.ml-n10 {
    margin-left: -10%;
}

.title-style-five .sc-title {
  font-size: 16px;
  color: #bebebe;
  letter-spacing: 4px;
  padding-bottom: 20px;
}

.title-style-five .sc-title-two {
  font-size: 17px;
  color: var(--prime-ten);
  padding-left: 40px;
  margin-bottom: 20px;
}

.title-style-five .sc-title-two:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 1px;
  background: var(--prime-ten);
  left: 0;
  top: 14px;
}

.title-style-five .main-title {
  font-size: 72px;
  line-height: 1.25em;
}

.title-style-five .main-title > span {
  z-index: 1;
}

.title-style-five .main-title .mark-bg {
  position: absolute;
  left: 0;
  bottom: 17px;
  width: 100%;
  height: 13px;
  z-index: -1;
}

.inner-banner-three .bg-wrapper {
  //background: url(../images/assets/bg-16.svg) no-repeat center;
  background-size: cover;
  padding: 150px 0 108px;
  position: relative;
  z-index: 1;
}


.contact-section-two {
  background: #fff;
  border-radius: 20px;
  padding: 60px 80px 85px;
  box-shadow: 0px 35px 70px rgba(0, 104, 31, 0.05);
}

.fancy-feature-fiftyFour .bg-wrapper {
  background: #e8faf3;
}

.fancy-feature-fiftyFour .shape-one {
  top: 15%;
  left: 9%;
}

.fancy-feature-fiftyFour .shape-two {
  top: 5%;
  right: 35%;
}

.fancy-feature-fiftyFour .shape-three {
  top: 50%;
  right: 4%;
}

.fancy-feature-fiftyFour .shape-four {
  bottom: 20%;
  left: 30%;
}

.card-style-twentyFour {
  padding: 50px 30px 10px;
  border-radius: 7px;
}

.card-style-twentyFour .icon {
  height: 42px;
}

.contact-section-two {
  background: #fff;
  border-radius: 20px;
  padding: 60px 80px 85px;
  box-shadow: 0px 35px 70px rgba(0, 104, 31, 0.05);
}

.form-style-three .input-group-meta label {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 7px;
}

.form-style-three .input-group-meta input {
  width: 100%;
  height: 60px;
  border-radius: 8px;
  border: 2px solid #000;
  padding: 0 5px 0 20px;
  color: #000;
  font-size: 17px;
}

.form-style-three .input-group-meta textarea {
  width: 100%;
  max-width: 100%;
  height: 165px;
  border-radius: 8px;
  border: 2px solid #000;
  padding: 15px 5px 15px 20px;
  color: #000;
  font-size: 17px;
}

.form-style-three ::-webkit-input-placeholder {
  color: #000;
}

.form-style-three :-ms-input-placeholder {
  color: #000;
}

.form-style-three ::placeholder {
  color: #000;
}

.address-block-three .icon {
  width: 35px;
  margin-top: 7px;
}

.address-block-three .icon img {
  margin-left: auto;
}

.address-block-three .text {
  width: calc(100% - 35px);
  padding-left: 30px;
}

.address-block-three .title {
  font-size: 26px;
  margin-bottom: 8px;
}

.address-block-three p {
  font-size: 18px;
  line-height: 1.65em;
  color: #878787;
  margin-bottom: 0;
}

.address-block-three p .call {
  font-size: 22px;
  color: var(--prime-two);
  margin-top: 3px;
}

.address-block-three p .call:hover {
  text-decoration: underline;
}

.address-block-three p .webaddress {
  color: #000;
  text-decoration: underline;
}

@media (min-width: 768px) {
  .margin-md-5p {
    margin: auto 5%;
  }
}
